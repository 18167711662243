/* App.css */
.App {
  text-align: center;
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
  height: auto;
}

.form-container-fade-out {
  height: fit-content;
}

.logo {
  max-width: 300px;
  margin-bottom: 20px;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
}

.fade {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade-out {
  height: 0;
  opacity: 0;
}
